<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      :page.sync="page"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft" slot-scope="scope">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="openDislog('add')"
          >同步
        </el-button>
        <el-input
          v-model="searchText"
          @keyup.native.enter="refreshChange"
          size="small"
          placeholder="请输入设备名称"
          type="text"
          style="width: 250px"
        >
          <el-button
            class="mar-0 pad-0"
            size="small"
            @click="refreshChange"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          size="small"
          class="none-border"
          icon="el-icon-view"
          @click="goDetail(scope.row)"
          >查看
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getMetaCommonList, delMetaCommon } from "@/api/busiMode/metaCommon";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      searchText: "",
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      loading: false,
      option: {
        height: "auto",
        calcHeight: 30,
        dialogWidth: 900,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        // selection: true,
        labelWidth: 120,
        menuWidth: 100,
        viewBtn: false,
        dialogClickModal: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        cancelBtn: false,
        column: [
          {
            width: 150,
            overHidden: true,
            align: "left",
            label: "设备名称",
            prop: "name",
            hide: false,
          },
          {
            width: 120,
            overHidden: true,
            align: "left",
            label: "终端类型",
            prop: "type",
            hide: false,
          },
          {
            width: 120,
            overHidden: true,
            align: "left",
            label: "电子类型",
            prop: "sub_type",
            hide: false,
          },

          {
            width: 120,
            overHidden: true,
            align: "left",
            label: "所在场所",
            prop: "place_name",
            hide: false,
          },
          {
            width: 120,
            overHidden: true,
            label: "场所类型",
            prop: "place_type",
            dicData: [
              {
                value: "0",
                label: "园区",
              },
              {
                value: "1",
                label: "楼宇",
              },
              {
                value: "2",
                label: "楼层",
              },
              {
                value: "3",
                label: "房间",
              },
              {
                value: "4",
                label: "会议室",
              },
            ],
          },
          {
            width: 120,
            overHidden: true,
            label: "联系人",
            prop: "contacts",
          },

          {
            width: 120,
            overHidden: true,
            label: "联系电话",
            prop: "contacts_phone",
            hide: false,
          },
          {
            width: 200,
            overHidden: true,
            label: "详细地址描述",
            prop: "address",
            hide: false,
          },

          {
            width: 100,
            overHidden: true,
            label: "创建时间",
            prop: "create_time",
            hide: false,
          },
        ],
      },
      model: "wisdom_park.wp_publish_device",
      data: [],

      ruleModel: {},
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      };
    },
  },
  mounted() {},
  methods: {
    goDetail(row) {
      this.$router.push({
        path: "/information/equipmentDetail",
        query: { equipment: row.id },
      });
    },
    refreshChange() {
      this.onLoad(this.page);
    },
    onLoad() {
      this.loading = true;
      let body = {
        current: this.page.currentPage,
        size: this.page.pageSize,
      };
      if (this.searchText) {
        body["wheres"] = [
          { field: "name", op: "LIKE", value: this.searchText },
        ];
      }
      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;

        this.loading = false;
        // this.selectionClear();
      });
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
  font-size: 18px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 20px;
  margin-bottom: 0px;
}
/deep/ .el-drawer__body {
  padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
  padding: 0px;
}
</style>
